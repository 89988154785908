form {
  align-items: center;
  align-self: flex-start;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: flex-end;
  line-height: 22px;
  max-width: 450px;
}

.small-field-container {
  width: calc(50% - 15px);
}

.small-field-container input {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid black;
  height: 49px;
  margin-bottom: 30px;
  padding: 10px;
  width: 100%;
}

.small-field-container:nth-child(1) {
  margin-right: 30px;
}

.large-field-container {
  width: 100%;
}

.large-field-container textarea {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid black;
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 239px;
  line-height: 22px;
  padding: 10px;
  resize: none;
  width: 100%;
}

.small-field-container input::placeholder,
.large-field-container textarea::placeholder {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.small-field-container input:focus,
.large-field-container textarea:focus {
  background-color: rgba(255,255,255,.5);
  outline-width: 0;
}

.button-link__form {
  border: 0;
  cursor: pointer;
}

.svg--maya {
  align-self: flex-end;
  max-width: 450px;
  width: 40%;
}

@media only screen and (max-width: 1200px) {
  form {
    align-self: center;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: unset;
    width: 100%;
  }
  
  .small-field-container {
    width: 100%;
  }
  
  .small-field-container input {
    margin-bottom: 20px;
  }
  
  .small-field-container:nth-child(1) {
    margin-right: 0px;
  }
  
  .button-link__form {
    align-self: flex-end;
    margin-top: 20px;
  }
  
 .content-container--contact .side-by-side {
   flex-direction: column;
 }
 
 .svg--maya {
   align-self: center;
   width: 100%;
 }
}
