.tech-element {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}

.tech-element__icon {
  width: 30px;
}

.tech-element__name {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px;
}

.tech-element__divider {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .tech-element {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .tech-element {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  
  .tech-element__icon {
    display: none;
  }
  
  .tech-element__divider {
    display: block;
    margin-left: 10px;
  }
}