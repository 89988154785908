@import url(https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&family=Hind+Vadodara:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}
@font-face {
  font-family: 'Peace Sans';
  src: url(/static/media/PeaceSans.44def464.woff) format('woff'),
  url(/static/media/PeaceSans.321a140e.woff2) format('woff2'),
  url(/static/media/PeaceSans.a46f475c.ttf) format('ttf'),
  url(/static/media/PeaceSans.f59a8de8.otf) format('otf');
}

.App {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	display: -webkit-flex;
	display: flex;
	height: 100vh;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	position: relative;
  text-align: center;
  width: 100%;
}

nav {
	height: 100vh;
	left: 0;
  position: fixed;
  top: 0;
  width: 100%;
	z-index: 3;
}

main {
	position: relative;
	z-index: 2;
}

h1 {
  color: #000000;
  font-family: 'Peace Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 139px;
  text-transform: uppercase;
}

h2 {
	color: #000000;
	font-family: 'Hind Vadodara', sans-serif;
	font-size: 40px;
	font-weight: 400;
	line-height: 60px;
}

b {
	font-weight: 700;
}

ul {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	display: -webkit-flex;
	display: flex;
	height: 100vh;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}

li {
	height: 100vh;
	/* width: 80px; */
}

li.empty {
	/* flex: 1; */
	background-color: transparent;
	/* width:0; */
}

main .content-container {
	width: calc(100% - 240px);
}

.hamburger {
	display: none;
}

.hamburger rect {
	-webkit-transform-origin: center center;
	        transform-origin: center center;
	transition: y .2s ease .1s, -webkit-transform .2s ease;
	transition: y .2s ease .1s, transform .2s ease;
	transition: y .2s ease .1s, transform .2s ease, -webkit-transform .2s ease;
}

.hamburger.animate-cross .dark {
	-webkit-transform: rotateZ(45deg);
	        transform: rotateZ(45deg);
}

.hamburger.animate-cross .gray {
	-webkit-transform: rotateZ(-45deg);
	        transform: rotateZ(-45deg);
}

.hamburger.animate-cross rect {
	y: 6;
}

.side-by-side {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 50px;
}

.top-bottom {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.side-by-side--start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.side-by-side--top {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 70px;
    line-height: 97px;
  }
}

@media only screen and (max-width: 1000px) {
  .side-by-side.mobile-flex-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .align-start-mobile .top-bottom {
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }
}

@media only screen and (max-width: 700px) {
	.side-by-side {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
	
	h1 {
    font-size: 40px;
    line-height: 56px;
  }
	
	.hamburger {
		display: block;
	  position: absolute;
		right: 20px;
	  top: 20px;
	  width: 35px;
	  z-index: 2000;
	}
	
 ul {
	 display: none;
 }
 
 ul.menu-show {
	 display: -webkit-flex;
	 display: flex;
 }
 
 li {
	 opacity: 0 !important;
	 transition: opacity .5s ease !important;
	 width: 25% !important;
 }
 
 ul.menu-show li {
	 opacity: 1 !important;
 }
 
 li.current {
	 opacity: 1 !important;
 }
 
 .link.hide {
	 opacity: 1 !important;
	 visibility: visible !important;
 }
 
 main .content-container {
 	width: 100%;
 }
 
 h2 {
	 font-size: 24px;
	 line-height: 36px;
 }
}
li {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	height: 100vh;
	-webkit-justify-content: center;
	        justify-content: center;
  position: relative;
	transition: width .5s ease;
  width:100%;
}

.link.show {
  opacity: 1;
}

.link.hide {
  opacity: 0 !important;
	transition: opacity .2s ease 0s, visibility .2s ease 0s;
  visibility: hidden;
}

li.animate.animate--left {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

li.animate.animate--right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.link {
	transition: opacity .2s ease .5s;
  width: 80px;
}

li {
  width: 80px;
  z-index: 3;
}

li.current {
  width: calc(100% - 240px);
  opacity: 0;
  z-index: -1;
}

.link {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	min-width: 100vh;
  transform: rotate(90deg);
  /* Safari */
  -webkit-transform: rotate(90deg);
  /* Firefox */
  -moz-transform: rotate(90deg);
  /* IE */
  -ms-transform: rotate(90deg);
  /* Opera */
  -o-transform: rotate(90deg);
	/* text-align: center; */
	width: 100vh;
}

.link:hover .highlight {
	background-color: white;
	height: 20px;
}

.link a {
	color: #000000;
	font-family: 'Hind Vadodara', sans-serif;
	font-size: 20px;
  font-style: normal;
  font-weight: 500;
	min-width: 100%;
	text-align: center;
	text-decoration: none;
}
.results-container {
  margin-top: 50px;
  transition: opacity .5s ease;
}

.reveal {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.pie-chart {
  margin-right: 15px;
  width: 250px;
}

.Yes {
  color: #FFBA08 !important;
}

.No {
  color: rgb(63, 136, 197) !important;
}

.svg--dog {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-right: 20px;
  margin-top: 50px;
  max-width: 700px;
  width: 45%;
}

.svg--dog path {
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}

@media only screen and (max-width: 1000px) {
  .results-container {
    margin-top: 40px;
  }
  
  .invisible {
    display: none;
  }
  
  .reveal {
    display: block;
  }
  
  .pie-chart {
    width: 80%;
  }
  
  .svg--dog {
    -webkit-order: 2;
            order: 2;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .svg--dog {
    margin-top: 50px;
    width: 100%;
  }
}
.shadow {
  left: 90px;
  opacity: .2;
  position: absolute;
  top: 70px;
}

.optional-br {
  display: none;
}

@media only screen and (max-width: 911px) {
  .optional-br {
    display:block;
  }
}

@media only screen and (max-width: 700px) { 
 .shadow {
   left: 25px;
   top: 45px;
 }
}
.buttons-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.buttons-container--start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.buttons-container .button-link {
  width: auto;
}

.buttons-container .button-link:nth-child(1) {
  margin-right: 20px;
}
.button-link {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.button-link__main--shadow {
  background-color: black;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  max-width: 200px;
  min-width: 100px;
  padding: 15px;
  position: relative;
  transition: left .25s ease-out, bottom .25s ease-out;
  z-index: 1;
}

.button-link__main--shadow::before {
  background-color: rgba(0,0,0,.2);
  content: '';
  height: 100%;
  left: -10px;
  position: absolute;
  top: 10px;
  transition: left .25s ease-out, top .25s ease-out;
  width: 100%;
  z-index: -1;
}

.button-link__main--shadow:hover {
  bottom: -10px;
  left: -10px;
}

.button-link__main--shadow:hover::before {
  left: 0px;
  top: 0px;
}

.button-link__main--plain {
  background-color: white;
  border-radius: 6px;
  margin: auto;
  max-width: 350px;
  padding: 10px;
  transition: background-color .3s ease-out;
  width: 100%;
}

.button-link__main--plain:hover {
  background-color: rgba(255,255,255,.4);
}

.button__label {
  color: white;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.button-link__main--plain .button__label {
  color: black;
}

@media only screen and (max-width: 700px) {
  .button-link__main--plain {
    background-color: black;
    margin: auto;
    margin-bottom: auto auto 5px auto;
  }
  
  .button-link__main--plain .button__label {
    color: white;
  }
}
.label {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
}

.color-square {
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.label-text {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.project-section {
  border-top: 1px solid rgba(0,0,0,0.2);
  margin-bottom: 30px;
  max-width: 1500px;
  padding-top: 10px;
}

.projects-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 1300px;
}
.project {
  height: 330px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: calc(50% - 15px);
}

.project-image-container {
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  width: auto;
  width: 100%;
}

.project:hover .project-image-container {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.project-overlay {
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0,0,0,0.8);
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  opacity: 0;
  padding: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity .5s ease;
  width: 100%;
}

.project:hover .project-overlay {
  opacity: 1;
}

.project-name {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
}

.techs {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 15px;
}

.tech-box {
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(255, 186, 8, .7);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0px 2.5px 0px 2.5px;
  padding: 10px;
}

.tech-box__label {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.buttons {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}


.project-info--mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .project {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .project-overlay {
    display: none;
  }
  
  .project-name {
    font-size: 20px;
  }
  
  .project-info--mobile {
    display: block;
    text-align: center;
    width: 100%;
  }
  
  .buttons--mobile {
    margin-bottom: 40px;
  }
  
  .buttons--desktop {
    display: none;
  }
  
  .project-info--mobile .body-paragraph {
    color: black;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .techs {
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .tech-box {
    background-color: black;
  }
  
  .tech-box__label {
    color: rgba(255, 186, 8, 1);
  }
}

@media only screen and (max-width: 700px) {
  .project {
    height: 200px;
    margin-bottom: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 1550px) and (max-height: 1170px) {
  .content-container--about-me .body-paragraph {
    width: calc(100% - 500px);
  }
}

@media only screen and (max-width: 1000px) {
  .content-container--about-me .body-paragraph {
    width: 90%;
  }
 .svg--cat-container--outer {
   margin: auto;
   position: relative !important;
 }
}
.tech-section {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-bottom: 30px;
}

.tech-section__name {
  font-family: 'Hind Madurai', sans-serif;
  font-weight: 600;
  min-width: 100px;
}

.tech-section__elements {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

@media only screen and (max-width: 1000px) {
  .tech-section__elements {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .tech-section {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  
  .tech-section__name {
    line-height: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .tech-section {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .tech-section__name {
    line-height: unset;
  }
}
.tech-element {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-right: 20px;
}

.tech-element__icon {
  width: 30px;
}

.tech-element__name {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px;
}

.tech-element__divider {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .tech-element {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .tech-element {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  
  .tech-element__icon {
    display: none;
  }
  
  .tech-element__divider {
    display: block;
    margin-left: 10px;
  }
}
form {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  color: #000000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  line-height: 22px;
  max-width: 450px;
}

.small-field-container {
  width: calc(50% - 15px);
}

.small-field-container input {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid black;
  height: 49px;
  margin-bottom: 30px;
  padding: 10px;
  width: 100%;
}

.small-field-container:nth-child(1) {
  margin-right: 30px;
}

.large-field-container {
  width: 100%;
}

.large-field-container textarea {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid black;
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 239px;
  line-height: 22px;
  padding: 10px;
  resize: none;
  width: 100%;
}

.small-field-container input::-webkit-input-placeholder, .large-field-container textarea::-webkit-input-placeholder {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.small-field-container input:-ms-input-placeholder, .large-field-container textarea:-ms-input-placeholder {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.small-field-container input::placeholder,
.large-field-container textarea::placeholder {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.small-field-container input:focus,
.large-field-container textarea:focus {
  background-color: rgba(255,255,255,.5);
  outline-width: 0;
}

.button-link__form {
  border: 0;
  cursor: pointer;
}

.svg--maya {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  max-width: 450px;
  width: 40%;
}

@media only screen and (max-width: 1200px) {
  form {
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 30px;
    max-width: unset;
    width: 100%;
  }
  
  .small-field-container {
    width: 100%;
  }
  
  .small-field-container input {
    margin-bottom: 20px;
  }
  
  .small-field-container:nth-child(1) {
    margin-right: 0px;
  }
  
  .button-link__form {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    margin-top: 20px;
  }
  
 .content-container--contact .side-by-side {
   -webkit-flex-direction: column;
           flex-direction: column;
 }
 
 .svg--maya {
   -webkit-align-self: center;
           align-self: center;
   width: 100%;
 }
}

.content-container.show {
  opacity: 1;
}

.content-container.hide {
  /* display: none; */
  opacity: 0;
  transition: opacity .2s ease 0s, visibility .2s ease 0s;
  visibility: hidden;
}

.content-container {
  left: 0;
  min-height: 100vh;
  padding: 80px;
  position: absolute;
  top: 0;
  transition: opacity .5s ease .5s, visibility .1s ease .5s;
}

.content-container--inner {
  max-width: 1100px;
}

.content-container--home {
  left: 0;
}

.svg--cat-container--outer {
  bottom: 0;
  position: absolute;
  right: 80px;
  width: 450px;
}

.svg--cat--container {
  height: 450px;
  position: relative;
}

.svg--cat {
  height: 100%;
  position: absolute;
}

.content-container--projects {
  left: 80px;
}

.content-container--about-me {
  left: 160px;
  padding-bottom: 0px;
}

.content-container--contact {
  left: 240px;
}

path {
  -webkit-animation: dash 6s linear forwards;
          animation: dash 6s linear forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.body-paragraph {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 30px;
  max-width: 800px;
}
.body-paragraph--larger {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0px;
}

.subheader {
  color: #000000;
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  margin-bottom: 30px;
}

.body-paragraph.light {
  color: #FFFFFF;
}

.body-paragraph b {
  font-family: 'Hind Madurai', sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 700px) {
 .content-container {
   padding: 50px 20px 20px 20px;
 }
 
 .content-container--about-me {
   padding-bottom: 0px;
 }
 
 .content-container--projects {
   left: 0px;
 }
 
 .content-container--about-me {
   left: 0px;
 }
 
 .content-container--contact {
   left: 0px;
 }
 
 .subheader {
   font-size: 24px;
   line-height: 36px;
 }
 
 .svg--cat-container--outer {
   bottom: unset;
   margin-top: 50px;
   position: relative;
   right: unset;
   width: 80%;
   /* width: 40%; */
 }
 
 .svg--cat--container {
   height: 300px;
 }
}
