.results-container {
  margin-top: 50px;
  transition: opacity .5s ease;
}

.reveal {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.pie-chart {
  margin-right: 15px;
  width: 250px;
}

.Yes {
  color: #FFBA08 !important;
}

.No {
  color: rgb(63, 136, 197) !important;
}

.svg--dog {
  align-self: flex-start;
  margin-right: 20px;
  margin-top: 50px;
  max-width: 700px;
  width: 45%;
}

.svg--dog path {
  animation-delay: 5s;
}

@media only screen and (max-width: 1000px) {
  .results-container {
    margin-top: 40px;
  }
  
  .invisible {
    display: none;
  }
  
  .reveal {
    display: block;
  }
  
  .pie-chart {
    width: 80%;
  }
  
  .svg--dog {
    order: 2;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .svg--dog {
    margin-top: 50px;
    width: 100%;
  }
}