li {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
  position: relative;
	transition: width .5s ease;
  width:100%;
}

.link.show {
  opacity: 1;
}

.link.hide {
  opacity: 0 !important;
	transition: opacity .2s ease 0s, visibility .2s ease 0s;
  visibility: hidden;
}

li.animate.animate--left {
  justify-content: flex-start;
}

li.animate.animate--right {
  justify-content: flex-end;
}

.link {
	transition: opacity .2s ease .5s;
  width: 80px;
}

li {
  width: 80px;
  z-index: 3;
}

li.current {
  width: calc(100% - 240px);
  opacity: 0;
  z-index: -1;
}

.link {
	align-items: center;
	display: flex;
	justify-content: center;
	min-width: 100vh;
  transform: rotate(90deg);
  /* Safari */
  -webkit-transform: rotate(90deg);
  /* Firefox */
  -moz-transform: rotate(90deg);
  /* IE */
  -ms-transform: rotate(90deg);
  /* Opera */
  -o-transform: rotate(90deg);
	/* text-align: center; */
	width: 100vh;
}

.link:hover .highlight {
	background-color: white;
	height: 20px;
}

.link a {
	color: #000000;
	font-family: 'Hind Vadodara', sans-serif;
	font-size: 20px;
  font-style: normal;
  font-weight: 500;
	min-width: 100%;
	text-align: center;
	text-decoration: none;
}