.content-container.show {
  opacity: 1;
}

.content-container.hide {
  /* display: none; */
  opacity: 0;
  transition: opacity .2s ease 0s, visibility .2s ease 0s;
  visibility: hidden;
}

.content-container {
  left: 0;
  min-height: 100vh;
  padding: 80px;
  position: absolute;
  top: 0;
  transition: opacity .5s ease .5s, visibility .1s ease .5s;
}

.content-container--inner {
  max-width: 1100px;
}

.content-container--home {
  left: 0;
}

.svg--cat-container--outer {
  bottom: 0;
  position: absolute;
  right: 80px;
  width: 450px;
}

.svg--cat--container {
  height: 450px;
  position: relative;
}

.svg--cat {
  height: 100%;
  position: absolute;
}

.content-container--projects {
  left: 80px;
}

.content-container--about-me {
  left: 160px;
  padding-bottom: 0px;
}

.content-container--contact {
  left: 240px;
}

path {
  animation: dash 6s linear forwards;
  animation-delay: 2.5s;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.body-paragraph {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 30px;
  max-width: 800px;
}
.body-paragraph--larger {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0px;
}

.subheader {
  color: #000000;
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  margin-bottom: 30px;
}

.body-paragraph.light {
  color: #FFFFFF;
}

.body-paragraph b {
  font-family: 'Hind Madurai', sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 700px) {
 .content-container {
   padding: 50px 20px 20px 20px;
 }
 
 .content-container--about-me {
   padding-bottom: 0px;
 }
 
 .content-container--projects {
   left: 0px;
 }
 
 .content-container--about-me {
   left: 0px;
 }
 
 .content-container--contact {
   left: 0px;
 }
 
 .subheader {
   font-size: 24px;
   line-height: 36px;
 }
 
 .svg--cat-container--outer {
   bottom: unset;
   margin-top: 50px;
   position: relative;
   right: unset;
   width: 80%;
   /* width: 40%; */
 }
 
 .svg--cat--container {
   height: 300px;
 }
}