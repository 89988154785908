/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&family=Hind+Vadodara:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'Peace Sans';
  src: url('./fonts/PeaceSans.woff') format('woff'),
  url('./fonts/PeaceSans.woff2') format('woff2'),
  url('./fonts/PeaceSans.ttf') format('ttf'),
  url('./fonts/PeaceSans.otf') format('otf');
}

.App {
	align-items: flex-start;
	display: flex;
	height: 100vh;
	justify-content: space-around;
	position: relative;
  text-align: center;
  width: 100%;
}

nav {
	height: 100vh;
	left: 0;
  position: fixed;
  top: 0;
  width: 100%;
	z-index: 3;
}

main {
	position: relative;
	z-index: 2;
}

h1 {
  color: #000000;
  font-family: 'Peace Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 139px;
  text-transform: uppercase;
}

h2 {
	color: #000000;
	font-family: 'Hind Vadodara', sans-serif;
	font-size: 40px;
	font-weight: 400;
	line-height: 60px;
}

b {
	font-weight: 700;
}

ul {
	align-items: flex-start;
	display: flex;
	height: 100vh;
	justify-content: flex-start;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}

li {
	height: 100vh;
	/* width: 80px; */
}

li.empty {
	/* flex: 1; */
	background-color: transparent;
	/* width:0; */
}

main .content-container {
	width: calc(100% - 240px);
}

.hamburger {
	display: none;
}

.hamburger rect {
	transform-origin: center center;
	transition: y .2s ease .1s, transform .2s ease;
}

.hamburger.animate-cross .dark {
	transform: rotateZ(45deg);
}

.hamburger.animate-cross .gray {
	transform: rotateZ(-45deg);
}

.hamburger.animate-cross rect {
	y: 6;
}

.side-by-side {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.top-bottom {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.side-by-side--start {
  justify-content: flex-start;
}

.side-by-side--top {
  align-items: flex-start !important;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 70px;
    line-height: 97px;
  }
}

@media only screen and (max-width: 1000px) {
  .side-by-side.mobile-flex-start {
    align-items: flex-start !important;
    flex-direction: column;
    justify-content: flex-start !important;
  }
  .align-start-mobile .top-bottom {
    align-self: flex-start;
  }
}

@media only screen and (max-width: 700px) {
	.side-by-side {
    flex-direction: column;
  }
	
	h1 {
    font-size: 40px;
    line-height: 56px;
  }
	
	.hamburger {
		display: block;
	  position: absolute;
		right: 20px;
	  top: 20px;
	  width: 35px;
	  z-index: 2000;
	}
	
 ul {
	 display: none;
 }
 
 ul.menu-show {
	 display: flex;
 }
 
 li {
	 opacity: 0 !important;
	 transition: opacity .5s ease !important;
	 width: 25% !important;
 }
 
 ul.menu-show li {
	 opacity: 1 !important;
 }
 
 li.current {
	 opacity: 1 !important;
 }
 
 .link.hide {
	 opacity: 1 !important;
	 visibility: visible !important;
 }
 
 main .content-container {
 	width: 100%;
 }
 
 h2 {
	 font-size: 24px;
	 line-height: 36px;
 }
}