.label {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
}

.color-square {
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.label-text {
  color: #000000;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}