.shadow {
  left: 90px;
  opacity: .2;
  position: absolute;
  top: 70px;
}

.optional-br {
  display: none;
}

@media only screen and (max-width: 911px) {
  .optional-br {
    display:block;
  }
}

@media only screen and (max-width: 700px) { 
 .shadow {
   left: 25px;
   top: 45px;
 }
}