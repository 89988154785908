.project-section {
  border-top: 1px solid rgba(0,0,0,0.2);
  margin-bottom: 30px;
  max-width: 1500px;
  padding-top: 10px;
}

.projects-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1300px;
}