.project {
  height: 330px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: calc(50% - 15px);
}

.project-image-container {
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  transition: transform .5s ease;
  width: auto;
  width: 100%;
}

.project:hover .project-image-container {
  transform: scale(1.05);
}

.project-overlay {
  align-items: center;
  background-color: rgba(0,0,0,0.8);
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity .5s ease;
  width: 100%;
}

.project:hover .project-overlay {
  opacity: 1;
}

.project-name {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
}

.techs {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tech-box {
  align-items: center;
  background-color: rgba(255, 186, 8, .7);
  display: flex;
  justify-content: center;
  margin: 0px 2.5px 0px 2.5px;
  padding: 10px;
}

.tech-box__label {
  color: black;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.project-info--mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .project {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .project-overlay {
    display: none;
  }
  
  .project-name {
    font-size: 20px;
  }
  
  .project-info--mobile {
    display: block;
    text-align: center;
    width: 100%;
  }
  
  .buttons--mobile {
    margin-bottom: 40px;
  }
  
  .buttons--desktop {
    display: none;
  }
  
  .project-info--mobile .body-paragraph {
    color: black;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .techs {
    justify-content: center;
  }
  
  .tech-box {
    background-color: black;
  }
  
  .tech-box__label {
    color: rgba(255, 186, 8, 1);
  }
}

@media only screen and (max-width: 700px) {
  .project {
    height: 200px;
    margin-bottom: 10px;
    width: 100%;
  }
}