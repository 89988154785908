.button-link {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.button-link__main--shadow {
  background-color: black;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  max-width: 200px;
  min-width: 100px;
  padding: 15px;
  position: relative;
  transition: left .25s ease-out, bottom .25s ease-out;
  z-index: 1;
}

.button-link__main--shadow::before {
  background-color: rgba(0,0,0,.2);
  content: '';
  height: 100%;
  left: -10px;
  position: absolute;
  top: 10px;
  transition: left .25s ease-out, top .25s ease-out;
  width: 100%;
  z-index: -1;
}

.button-link__main--shadow:hover {
  bottom: -10px;
  left: -10px;
}

.button-link__main--shadow:hover::before {
  left: 0px;
  top: 0px;
}

.button-link__main--plain {
  background-color: white;
  border-radius: 6px;
  margin: auto;
  max-width: 350px;
  padding: 10px;
  transition: background-color .3s ease-out;
  width: 100%;
}

.button-link__main--plain:hover {
  background-color: rgba(255,255,255,.4);
}

.button__label {
  color: white;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.button-link__main--plain .button__label {
  color: black;
}

@media only screen and (max-width: 700px) {
  .button-link__main--plain {
    background-color: black;
    margin: auto;
    margin-bottom: auto auto 5px auto;
  }
  
  .button-link__main--plain .button__label {
    color: white;
  }
}